@-webkit-keyframes line-spin-fade-loader {
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }
@keyframes line-spin-fade-loader {
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }
.line-spin-fade-loader {
  position: relative;
  top: -5px;
  left: -2px;
}
.line-spin-fade-loader > div:nth-child(1) {
    top: 10px;
    left: 0;
    -webkit-animation: line-spin-fade-loader 1.2s -0.84s infinite ease-in-out;
            animation: line-spin-fade-loader 1.2s -0.84s infinite ease-in-out; }
.line-spin-fade-loader > div:nth-child(2) {
    top: 6.81818182px;
    left: 6.81818182px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-animation: line-spin-fade-loader 1.2s -0.72s infinite ease-in-out;
            animation: line-spin-fade-loader 1.2s -0.72s infinite ease-in-out; }
.line-spin-fade-loader > div:nth-child(3) {
    top: 0;
    left: 10px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-animation: line-spin-fade-loader 1.2s -0.6s infinite ease-in-out;
            animation: line-spin-fade-loader 1.2s -0.6s infinite ease-in-out; }
.line-spin-fade-loader > div:nth-child(4) {
    top: -6.81818182px;
    left: 6.81818182px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-animation: line-spin-fade-loader 1.2s -0.48s infinite ease-in-out;
            animation: line-spin-fade-loader 1.2s -0.48s infinite ease-in-out; }
.line-spin-fade-loader > div:nth-child(5) {
    top: -10px;
    left: 0;
    -webkit-animation: line-spin-fade-loader 1.2s -0.36s infinite ease-in-out;
            animation: line-spin-fade-loader 1.2s -0.36s infinite ease-in-out; }
.line-spin-fade-loader > div:nth-child(6) {
    top: -6.81818182px;
    left: -6.81818182px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-animation: line-spin-fade-loader 1.2s -0.24s infinite ease-in-out;
            animation: line-spin-fade-loader 1.2s -0.24s infinite ease-in-out; }
.line-spin-fade-loader > div:nth-child(7) {
    top: 0;
    left: -10px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-animation: line-spin-fade-loader 1.2s -0.12s infinite ease-in-out;
            animation: line-spin-fade-loader 1.2s -0.12s infinite ease-in-out; }
.line-spin-fade-loader > div:nth-child(8) {
    top: 6.81818182px;
    left: -6.81818182px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-animation: line-spin-fade-loader 1.2s 0s infinite ease-in-out;
            animation: line-spin-fade-loader 1.2s 0s infinite ease-in-out; }
.line-spin-fade-loader > div {
    width: 4px;
    height: 35px;
    border-radius: 2px;
    margin: 2px;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    position: absolute;
    width: 3px;
    height: 7px; }
