html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  margin: 0 auto;
}

body {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

#root {
  display: flex;
  position: relative;
  min-height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast--error {
  background-color: #e03737 !important;
}

.Toastify__toast--success {
  background-color: #00b104 !important;
}

.Toastify__toast .title {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  color: #fff;
}

.Toastify__toast .message {
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
}